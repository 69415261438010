import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elEn from 'element-ui/lib/locale/lang/en'
import elZh from 'element-ui/lib/locale/lang/zh-CN'
import Element from 'element-ui'
import en from './en.js'
import zh from './zh.js'

Vue.use(VueI18n)

const messages = {
  en: {
    ...en,
    ...elEn,
  },
  zh: {
    ...zh,
    ...elZh,
  },
}
 function getNavigatorLanguage(){
    console.log(navigator.language, "=======getNavigatorLanguage======");
    var navigatorLanguage = navigator.language;// 获取浏览器语言
    if(navigatorLanguage){
      if(navigatorLanguage.indexOf('en') !=-1){
        localStorage.setItem('local','en')
        return 'en'
      }else if(navigatorLanguage.indexOf('zh') !=-1){
        localStorage.setItem('local','zh')
         return 'zh'
      }else{
        localStorage.setItem('local','en')
         return 'en'
      }
    }else{
      localStorage.setItem('local','en')
      return 'en'
    }
 }
const i18n = new VueI18n({
  locale: localStorage.getItem('local') || getNavigatorLanguage() ,
  messages,
  silentTranslationWarn: true,
})

Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})

export default i18n
