export default{
    Header:{
        首页: '首页',
        产品展示: '产品展示',
        供应链 : '供应链',
        公司资质:"公司资质",
        新闻中心: '新闻中心',
        关于我们: '关于我们',

        公司简介:"公司简介",
    },
    Footer:{
         扫码关注好面科技公众号: "扫码关注好面科技公众号",
         备案号: "浙ICP备2021036005号-2",
         栏目:"栏目",
         联系方式:"联系方式"
    },
    新闻:{
        新闻1标题:"欢迎参观好面的中国（广州）国际酒店用品及餐饮展览会",
        新闻1简介:"好面科技此次参加了2023中国（广州）国际酒店用品及餐饮展览会，参观时间2023年12月16日-18日，展会地点：中国进出口商品交易会展馆（广州市海珠区阅江中路380号）B区9.3馆504、507，欢迎大家来参观！\n",
        新闻2标题: '好面上海食品设备展成功结束',
        新闻2简介:"此次展会聚焦推广餐饮数智化解决方案，餐厨设备的创新应用，\n       " +
            "   拓市场建渠道及发掘新买家。同时以国内餐饮设备、智慧餐饮解决方案等餐饮产业为依托，展示餐饮产业新产品、新技术、新智能餐饮前沿应用为主的宣传和贸易交流平台。",
        详情:"详情",
        "发布时间：":"发布时间：",
        "新闻1-1":"在全国旅游发展大住宿、大旅游项目的背景下,酒店餐饮业的产业结构调整和升级已势在必行。酒店大住宿是大旅游的关键元素之一，随着大众出行需求的增加、消费者观念的变化、住宿需求的分化，中国酒店餐饮从早期的探索阶段，迈入多元业态发展阶段。越来越多的互联网科技巨头纷纷入局酒旅市场，助力酒店餐饮改善传统运营模式，加速进行数字化转型，帮助酒店智能化升级，为游客用户带来更便捷和个性化的入住体验后疫情时代传统酒店及餐饮行业升级智能化，消费者对科技化、体验化、场景化和定制化的酒店餐饮行业需求日趋强烈，。“2023年中国（广州）国际酒店用品及餐饮展览会”，为华南以及全国地区搭建酒店与餐饮业厂商合作交流的平台，为酒店餐饮行业的业务拓展提供更多的信息与商机。依托华南地区庞大的消费市场，整合酒店餐饮业市场资源，为参展商和买家搭建合作交流贸易平台，为酒店餐饮业的业务拓展提供更多的信息与商机。用以实现酒店及餐饮“特色、形象、外观、配套、服务”五大要素的呈现,最终为旅客提供舒适便捷的体验和服务。",
        "新闻1-2":"规模史无前例，展出面积340000 平方米 ，预计参观众总人数达250000+ 名 ，预计本届参展商 4000+家，本届目前登记申请参观采购350+ 个团。",
        "新闻1-3":"此次展会最大的亮点。1、展会规模盛大助您开拓未来市场，总规模预计达到34万平方米,将吸引近4000多家参展企业和预计超25万多名专业买家的参与，是您占领市场先机,开拓商业机遇、获取市场资讯和建立伙伴关系的战略平台。 2、主题展区鲜明，彰显酒店用品餐饮业采购新动向，根据国内外买家的采购需求,展会规划了各大主题展区,重点展示咖啡茶饮、餐饮设备、烹饪食材、烘焙与冰淇淋、智能家电、酒店用品综合。同时邀请行业权威人士，评比优秀技术和产品，引领采购方向。3、贸易对接周密打造行业顶级盛会，主办方通过多年来行业专业信息的积累与宣传，将向100万优质观众、300多家酒店餐饮集团发出个性化邀请,让展商可以与终端客户进行一对一治谈，当面直接了解酒店餐饮集团的需求。",
        "新闻1-4":"好面科技此次参加了2023中国（广州）国际酒店用品及餐饮展览会，参观时间2023年12月16日-18日，展会地点：中国进出口商品交易会展馆（广州市海珠区阅江中路380号）B区9.3馆504、507，欢迎大家来参观！",

        "新闻2-1":"2022年12月9日新冠疫情正是宣布结束，近3年的新冠疫情导致许多企业停滞不前。",
        "新闻2-2":"在此期间，我司研发了鲜食售卖机、鲜食现制机器人等相关餐饮设备。为了便利人们的生活，解决最后一公里吃饭问题，也为了更好的推广我司设备，于2023年8月23-8月25日在上海新国际博览中心参加了2023第14届上海国际餐饮设备及用品展览会，此次参展人员有王景康、陈士玉、罗杨杨、谢莹盈等。",
        "新闻2-3":"此次展会聚焦推广餐饮数智化解决方案，餐厨设备的创新应用，拓市场建渠道及发掘新买家。同时以国内餐饮设备、智慧餐饮解决方案等餐饮产业为依托，展示餐饮产业新产品、新技术、新智能餐饮前沿应用为主的宣传和贸易交流平台。",
        "新闻2-4":"展会以服务餐饮新业态为核心，以智慧餐饮、无人餐厅、餐饮无界、餐饮新零售为主题，深耕行业，细分市场，重点展示智慧餐饮技术、餐饮新零售终端及产品、解决方案等，同时召开中国智慧餐饮大会等论坛活动，开启智慧餐饮盛会。打造智慧餐饮产业一站式展示、交易平台!",
        "新闻2-5":"此次展会是我司成立以来参加的第一次展会，各部门领导十分重视，在紧张筹备一个月后，顺利参加展会。",
        "新闻2-6":"展会期间，许多观众对我们设备十分感兴趣，洽谈人员达到了300名，吸引了50多位传统餐饮的客户，认为我们设备优点众多，值得引进！",
    },
    首页:{
        产品展示简介:"公司已研发出第三代智能餐饮机器人，并已成功实现商用交付，持续平运营6个月以上，全国各地区市场正在有序开拓中。" ,
        行业积累:"行业积累",
        智能商用厨房方案解决专家:"智能商用厨房方案解决专家",
        年:'年',
        家:"家",
        合作企业:"合作企业",
        专业设备:"专业设备",
        套:"套",
        大:"大",
        解决方案:"解决方案",
        解决方案描述:"杭州好面科技有限公司于2021年成立，从智能商用后厨机器人切入，并基于“科技传承中国美食 数据驱动行业变革”的企业使命，\n" +
            "                      提出了一站式智能化解决方案，核心聚焦到经营基本单元--门店，提供包含菜品研发、供应链配送、机器无人化烹饪、\n" +
            "                      C端智能化点单、门店系统化运营推广在内的全闭环式开店运营服务，并以此推动行业的智能化改造升级。",
        供应链1:"一站式优质食材供应",
        供应链2:"食材批量工业化生产",
        供应链3:"大师级酱料配比",
        供应链4:"设备自动化生成",
        供应链5:"新鲜好吃",
        公司简介:" 杭州好面科技有限公司于2021年成立，从智能商用后厨机器人切入，并基于“科技传承中 国美食 数据驱动行业变革”的企业使命，提出了一站式智能化解决方案，核 心聚焦到经营基本单元--门店，提供包含菜品研发、供应链配送、机器无人 化烹饪、C端智能化点单、门店系统化运营推广在内的全闭环式开店运营服 务，并以此推动行业的智能化改造升级。\n" +
            "                ",
        "还是没有满意的产品，那么直接联系我们吧！":"还是没有满意的产品，那么直接联系我们吧！",
        电话咨询:"电话咨询",

    },
    产品展示:{
        下载资料:"下载资料" ,
        了解详情:"了解详情" ,
        产品名称1:"鲜食现制机器人",
        产品名称2:"鲜食售卖机",
        产品名称3:"鲜食售卖机",
        产品名称4:"移动端用户点餐程序",
        产品1简介:`杭州好面科技有限公司旗下核心产品，智能化商用厨房机器人设备。为适应着市场变 化与行业趋势，
                          不断进行着自我的更新迭代。最新版本通过冷藏与智能 补货模块、烹煮模块、机械臂模块，实现全流程智能化烹饪，大大节省 后厨空间，全程工作可视化，装配净化器，
                          烹饪食物的过程不会产生油 烟，设备稳定，更安静，更环保，现已达到商用化标准。`,
        产品2简介:`掌握低温锁鲜、风味加热技术，具备三大特点1，中央厨房生产全程冷链 运输；
                                  2，餐品安全加热高效急速出餐；3, 24小时营业满足应急之需。
                                   可在诸如车站、商场、小区、工业园区、写字楼等多种场景布置。打通 “最后100米”终端零售渠道。`,
        产品3简介:`杭州好面科技有限公司新一代餐机，配备全自动存储单元，最大支持60份餐品冷藏，可随时 补货。创新微压蒸煮一体，烹饪手法多样。
              支持涡流抑制冷却技术，运行 时无异味散发，制作餐品多样化早、中、晚三餐各类餐品均可制作，满足 客户不同需求。做到线上点餐，立即制作，线下取餐。`,
        产品4简介:`好面手机点餐程序是一款便捷的点餐手机软件，通过移动设备上的应用程序或小程序，用户可以随时随地浏览菜单、下单、支付并享受美食。`,
        好面产品:"好面产品",
        产品4介绍:"好面手机点餐程序是一款便捷的点餐手机软件，很好的兼容的好面鲜食售卖机的各类特性。通过移动设备上的应用程序或小程序，用户可以随时随地浏览菜单、下单、支付并享受美食。",
        功能特点:"功能特点",
        便捷支付:"便捷支付",
        品类丰富:"品类丰富",
        优惠福利:"优惠福利",
        便捷支付d:"支持多种支付方式，如微信支付、支付宝支付等，让用户下单更加方便快捷。",
        品类丰富d:"麻辣烫，盖浇饭，香锅冒菜多样化美食，手机点餐程序都能满足用户的各种需求。",
        优惠福利d:"提供各类优惠券和减免活动，让用户享受更多优惠。",
        使用流程:"使用流程",
        步骤1:"扫码进入小程序或下载应用程序并注册登录。",
        步骤2:"浏览菜单并选择菜品和数量，加入购物车。",
        步骤3:"在购物车中查看已选菜品数量和总价，进行结算。",
        步骤4:"填写收货信息，选择支付方式，确认订单无误后提交。",
        步骤5:"等待设备制作完成后到相应的线下设备前取餐。",

    },
    供应链:{
        内容:" 掌握低温锁鲜、风味加热技术，具备三大特点1，中央除非生产全程冷链运输；2，餐品安全加热高效急速出餐；" +
            "3,24小时营业满足应急之需。直击 消费者做饭难，配菜烦，节奏快等行业痛点。覆盖范围广，场景适配全面。可在诸如车站、商场、小区、工业园区、写字楼等多种场景布置。打通 “最后100米”终端零售渠道。",
        精选优质食材:"精选优质食材",
        机器人操作台:"机器人操作台",
        完善配料:"完善配料",
        安全食材:"安全食材",
        高效便捷:"高效便捷"
    },
    关于我们:{
       公司介绍:" 杭州好面科技有限公司于2021年成立，从智能商用后厨机器人切入，并基于“科技传承中国美食 数据驱动行业变革”的企业使命，\n" +
           "                提出了一站式智能化解决方案 ，核心聚焦到经营基本单元--门店，提供包含菜品研发、供应链配送、机器无人化烹饪、C端智能化点单、\n" +
           "                门店系统化运营推广在内的全闭环式开店运营服务，并以此推动行业的智能化改造升级。",
        地址:"地址",
        地址内容:"浙江省杭州市临平区星桥街道星发街22号银都产业园一号楼3楼",
        电话:"电话",
        邮箱:"邮箱"
    },
    公共:{
        更多:"更多"
    }
}
