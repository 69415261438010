export default{

    Header:{
        首页:"Home",
        产品展示: 'Product showcase' ,
        公司资质: 'Company qualifications',
        供应链:"Supply chain",
        新闻中心: 'News center',
        关于我们: 'About us',
        公司简介:"Company  Introduction"
    },

    Footer:{
        扫码关注好面科技公众号: "Scan the code to follow the Haomian Technology Co. official account",
        备案号: "Zhejiang ICP No. 2021036005-2",
        栏目:"Content",
        联系方式:"Contact information"
    },
    新闻:{
        "新闻1标题": "Welcome to the wonderful China (Guangzhou) International Hotel Supplies and Catering Exhibition",
        "新闻1简介": "Haomian Technology participated in the 2023 China (Guangzhou) International Hotel Supplies and Catering Exhibition. The visit time will be December 16 - 18, 2023. The exhibition location: China Import and Export Fair Exhibition Hall (No. 380 Middle Yuejiang Road, Haizhu District, Guangzhou City). Everyone is welcome to visit!\n",
        "新闻2标题": "Haomian Shanghai Food Equipment Exhibition successfully concluded",
        新闻2简介:"This exhibition focuses on promoting digital solutions for catering and innovative applications of kitchen equipment,\n" +
            "Expand the market, establish channels, and discover new buyers. At the same time, relying on domestic catering equipment, smart catering solutions and other catering industries, it is a promotion and trade exchange platform that showcases new products, technologies and cutting-edge applications of intelligent catering in the catering industry.",

        详情:"Detail",
        "发布时间：":"Release time：",
        "新闻1-1":"Against the backdrop of the development of large-scale accommodation and tourism projects in the country, it is imperative to adjust and upgrade the industrial structure of the hotel and catering industry. Hotel accommodation is one of the key elements of big tourism. With the increasing demand for mass travel, changes in consumer attitudes, and differentiation of accommodation needs, Chinese hotel catering has entered a stage of diversified business development from the early exploration stage. More and more Internet technology giants have entered the wine and tourism market, helping hotels and restaurants improve their traditional operation mode, accelerate digital transformation, help hotels upgrade intelligently, and bring more convenient and personalized check-in experience to tourists. In the post epidemic era, traditional hotels and catering industries upgrade intelligently. Consumers' demand for scientific, experiential, scene based and customized hotel and catering industries has become increasingly strong,. The 2023 China (Guangzhou) International Hotel Supplies and Catering Exhibition provides a platform for cooperation and exchange between hotels and catering industry manufacturers in southern China and across the country, offering more information and business opportunities for the expansion of the hotel catering industry. Relying on the huge consumer market in southern China, integrating hotel and catering industry market resources, building a cooperation and exchange trade platform for exhibitors and buyers, and providing more information and business opportunities for the business expansion of the hotel and catering industry. To present the five elements of \"characteristics, image, appearance, supporting facilities, and services\" of hotels and catering, ultimately providing passengers with a comfortable and convenient experience and service.",
        "新闻1-2":"The scale is unprecedented, with an exhibition area of 340000 square meters and an expected total of over 250000 visitors. It is estimated that there will be over 4000 exhibitors in this year, and 350+groups have registered to visit and purchase.",
        "新闻1-3":"The biggest highlight of this exhibition. 1. The grand scale of the exhibition will help you explore future markets. The total scale is expected to reach 340000 square meters, attracting nearly 4000 exhibiting companies and over 250000 professional buyers. It is a strategic platform for you to seize market opportunities, explore business opportunities, obtain market information, and establish partnerships. 2. The theme exhibition area is distinct, highlighting the new trends in the procurement of hotel supplies and catering industry. Based on the purchasing needs of domestic and foreign buyers, the exhibition has planned major theme exhibition areas, focusing on showcasing coffee and tea drinks, catering equipment, cooking ingredients, baking and ice cream, smart home appliances, and hotel supplies. At the same time, invite industry experts to evaluate excellent technologies and products, and lead the procurement direction. 3. The trade docking is carefully crafted to create a top-level event in the industry. Through years of accumulation and promotion of industry professional information, the organizer will issue personalized invitations to over 1 million high-quality visitors and more than 300 hotel and catering groups, allowing exhibitors to have one-on-one talks with end customers and directly understand the needs of hotel and catering groups in person.",
        "新闻1-4":"Haomian Technology participated in the 2023 China (Guangzhou) International Hotel Supplies and Catering Exhibition from December 16th to 18th, 2023. The exhibition will be held at the China Import and Export Fair Exhibition Hall (No. 380 Yuejiang Middle Road, Haizhu District, Guangzhou), Building 9.3, Rooms 504 and 507. Welcome to visit!",

        "新闻2-1":"On December 9, 2022, the COVID-19 epidemic was declared to be over. The COVID-19 epidemic in the past three years has led many enterprises to stagnate.",
        "新闻2-2":"During this period, our company developed related catering equipment such as fresh food vending machines and freshly made fresh food robots. In order to facilitate people's lives, solve the problem of last mile meals, and better promote our equipment, we participated in the 14th Shanghai International Catering Equipment and Supplies Exhibition from August 23 to August 25, 2023 at the Shanghai New International Expo Center. The attendees included Wang Jingkang, Chen Shiyu, Luo Yangyang, Xie Yingying, and others.",
        "新闻2-3":"This exhibition focuses on promoting digital solutions for catering, innovative applications of kitchen equipment, expanding the market, building channels, and discovering new buyers. At the same time, relying on domestic catering equipment, smart catering solutions and other catering industries, it is a promotion and trade exchange platform that showcases new products, technologies and cutting-edge applications of intelligent catering in the catering industry.",
        "新闻2-4":"The exhibition focuses on serving new catering formats, with themes of smart catering, unmanned restaurants, boundaryless catering, and new retail catering. It deeply cultivates the industry, segments the market, and highlights the display of smart catering technology, catering new retail terminals and products, solutions, etc. At the same time, forums and activities such as the China Smart Catering Conference are held to open up the grand event of smart catering. Build a one-stop display and trading platform for the smart catering industry!",
        "新闻2-5":"This exhibition is the first one our company has participated in since its establishment, and leaders from various departments attach great importance to it. After a month of intense preparation, we successfully participated in the exhibition.",
        "新闻2-6":"During the exhibition, many visitors were very interested in our equipment, with 300 negotiators and more than 50 traditional catering customers attracted. They believed that our equipment has many advantages and is worth introducing!",
    },
    首页:{
        "产品展示简介": "The company has developed the third-generation intelligent catering robot, and has successfully achieved commercial delivery. It has continued to operate for more than 6 months, and markets in all regions of the country are being developed in an orderly manner.",
        "行业积累": "Industry accumulation",
        "智能商用厨房方案解决专家": "Intelligent commercial kitchen solution expert",
        "年": "years",
        "家": "",
        "合作企业": "Cooperative enterprises",
        "专业设备": "Professional equipment",
        "套": "",
        "大": " major",
        "解决方案": "Solutions",
        "解决方案描述": "Hangzhou Haomian Technology Co., Ltd. was established in 2021. It starts from intelligent commercial kitchen robots and is based on the corporate mission of \"inheriting China's food data to drive industry transformation.\"\n                      A one-stop intelligent solution is proposed, with the core focus on the basic unit of operation-stores, providing services including dish research and development, supply chain distribution, unmanned machine cooking,\n                      It provides full-closed-loop store operation services including C-end intelligent ordering and systematic store operation promotion, and promotes the intelligent transformation and upgrading of the industry.",
        "供应链1": "One-stop supply of high-quality ingredients",
        "供应链2": "Batch industrial production of ingredients",
        "供应链3": "Master sauce ratio",
        "供应链4": "Automatic equipment generation",
        "供应链5": "Fresh and delicious",
        "公司简介": " Hangzhou Haomian Technology Co., Ltd. was established in 2021. Starting from intelligent commercial kitchen robots and based on the corporate mission of \"inheriting technology and Chinese food data to drive industry transformation\", it has proposed a one-stop intelligent solution, focusing on the basic unit of operation-stores, providing fully closed-loop store operation services including dish research and development, supply chain distribution, machine unmanned cooking, C-end intelligent ordering, and systematic store operation promotion. And in this way, promote the intelligent transformation and upgrading of the industry.\n                ",
        "还是没有满意的产品，那么直接联系我们吧！": "There are still no satisfactory products, then contact us directly!",
        "电话咨询": "Telephone consultation"


    },
    产品展示:{
        下载资料:"Download materials" ,
        了解详情:"Details" ,
        产品名称1:"Fresh food freshly made robot",
        产品名称2:"Fresh food vending machine",
        产品名称3:"Fresh food vending machine",
        产品名称4:"Mobile user ordering program",
        产品1简介:`The core product of Hangzhou Haomian Technology Co., Ltd. is intelligent commercial kitchen robot equipment. To adapt to market changes and industry trends,
Continuously undergoing self updating iterations. The latest version achieves full process intelligent cooking through refrigeration and intelligent replenishment modules, cooking modules, and robotic arm modules, greatly saving kitchen space, visualizing the entire process, and assembling purifiers,
The process of cooking food does not produce oil fumes, the equipment is stable, quieter, and more environmentally friendly, and has now reached commercial standards.`,
        产品2简介:`Mastering low-temperature freshness locking and flavor heating techniques, possessing three major characteristics: 1. Cold chain transportation throughout the entire production process of the central kitchen;
2. Food safety, efficient heating, and rapid serving; 3. 24-hour operation to meet emergency needs.
It can be arranged in various scenarios such as stations, shopping malls, residential areas, industrial parks, office buildings, etc. Open up the retail channel for the "last 100 meters" terminal.`,
        产品3简介:`The new generation dining machine of Hangzhou Haomian Technology Co., Ltd. is equipped with a fully automatic storage unit, which supports up to 60 servings of refrigerated food and can be restocked at any time. Innovative micro pressure steaming and cooking integration, diverse cooking techniques.
Supporting vortex suppression cooling technology, there is no odor emission during operation, and a variety of breakfast, lunch, and dinner dishes can be made to meet different customer needs. Order online, make immediately, and pick up offline.`,
        产品4简介:`Haomian Mobile Ordering Program is a convenient ordering mobile app that allows users to browse menus, place orders, make payments, and enjoy delicious food anytime and anywhere through applications or mini programs on their mobile devices.`,

        好面产品:"Products of Hangzhou Haomian Technology Co.",
        产品4介绍:"Haomian mobile ordering program is a convenient ordering mobile app that is well compatible with various features of Haomian fresh food vending machines. Through applications or mini programs on mobile devices, users can browse menus, place orders, make payments, and enjoy delicious food anytime and anywhere.",
        功能特点:"Functional Features",
        便捷支付:"Convenient payment",
        品类丰富:"Rich categories",
        优惠福利:"Discounted benefits",
        便捷支付d:"It supports a variety of payment methods, such as WeChat payment, Alipay payment, etc., making it easier and faster for users to place orders.",
        品类丰富d:"Spicy Hot Pot, covered rice, spicy pot Maocai, and mobile phone ordering programs can meet various needs of users.",
        优惠福利d:"Provide various coupons and discount activities to allow users to enjoy more discounts.",
        使用流程:"User flow",
        步骤1:"Scan the code to enter the WeChat mini program and register to log in.",
        步骤2:"Browse the menu and select dishes and quantities, then add them to the shopping cart.",
        步骤3:"Check the quantity and total price of selected dishes in the shopping cart for settlement.",
        步骤4:"Fill in the receiving information, select the payment method, confirm the order is correct, and submit.",
        步骤5:"After the equipment is completed, go to the corresponding offline device to pick up the meal.",
    },
    供应链:{
        内容:" Mastering low-temperature freshness locking and flavor heating technology, possessing three major characteristics: 1. Central cold chain transportation throughout the entire production process; 2. Food safety, efficient heating, and rapid serving; 3. 24-hour operation to meet emergency needs. Directly targeting the pain points of the industry, such as difficulty in cooking for consumers, annoyance with side dishes, and fast pace. Wide coverage and comprehensive scene adaptation. It can be arranged in various scenarios such as stations, shopping malls, residential areas, industrial parks, office buildings, etc. Open up the retail channel for the 'last 100 meters' terminal.",
        精选优质食材:"Selected high-quality ingredients",
        机器人操作台:"Robot operating table",
        完善配料:"Improve ingredients",
        安全食材:"Safe ingredients",
        高效便捷:"Efficient and convenient"

    },
    关于我们:{
        公司介绍:"Hangzhou Haomian Technology Co., Ltd. was established in 2021, starting with intelligent commercial kitchen robots and based on the corporate mission of 'inheriting Chinese cuisine through technology and driving industry transformation',A one-stop intelligent solution has been proposed, with a core focus on the basic business unit - stores, providing services including dish research and development, supply chain distribution, machine unmanned cooking, C-end intelligent orderingThe fully closed-loop store operation services, including the promotion of store systematic operation, are used to promote the intelligent transformation and upgrading of the industry."
        ,地址:"Address",
        地址内容:"3rd Floor, Building 1, Yindu Industrial Park, No. 22 Xingfa Street, Xingqiao Street, Linping District, Hangzhou City, Zhejiang Province",
        电话:"Contact Number",
        邮箱:"Email",
    },
    公共:{
        更多:"More"
    }
}
