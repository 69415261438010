import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css';
// 引入i18n国际化插件


import i18n from './i18n'
import ElementUI from 'element-ui';

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$baseURL = "https://homierobot.cn";//"https://homierobot.cn"
Vue.prototype.$baseRequestURL = "https://homierobot.cn/dev-api";//https://homierobot.cn"




import VueCoreVideoPlayer from 'vue-core-video-player'
//或者
Vue.use(VueCoreVideoPlayer, {
  lang: 'zh-CN'
})
import "vant/lib/index.css";
import {  Col, Row,Icon,Image as VanImage,Popup,Popover ,Button,Overlay,Swipe,SwipeItem   } from "vant"; // 按需引入,优化加载速度
Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(VanImage);
Vue.use(Popup);
Vue.use(Popover);
Vue.use(Button);
Vue.use(Overlay );
Vue.use(Swipe );
Vue.use(SwipeItem)
// 引入百度地图
import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  ak: "AmDS3u4rR8b3SpmGsj2ie0PxpCGiBVcm"
})
Vue.prototype.$lg = function (obj,prop,param) {
  console.log(obj,"====$lg=====",prop)
  return i18n.t ( "['"+obj+"']"+"['"+prop+"']",param)
}
Vue.prototype.$getLocal = function () {
  return localStorage.getItem('local')
}
new Vue({
  el: '#app',
  router,i18n,
  render: h => h(App),
}).$mount('#app')
//title
const defaultTitle = "好面官网";
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : defaultTitle;
  next();
});
